
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Services = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const images = [
    "https://uploads-ssl.webflow.com/6380611d34419190c5cb43e2/6380611d3441917db6cb4412_Group%2051.svg",
    "https://uploads-ssl.webflow.com/6380611d34419190c5cb43e2/6380611d344191a445cb440a_Group%2017.svg",
    "https://uploads-ssl.webflow.com/6380611d34419190c5cb43e2/6380611d344191dc65cb4411_Group%2016.svg",
  ]

  function RandomImage() {
    const index = Math.floor(Math.random() * images.length);
    return images[index];

  }

  return (
    <section className="skill" id="services">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>WHAT WE DO</h2>
                        <p>
                        Sugar is an online management & marketing agency that works with models to provide a hassle-free environment, allowing the content creators to focus solely on generating content.
                        </p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src="https://uploads-ssl.webflow.com/6380611d34419190c5cb43e2/6380611d3441917db6cb4412_Group%2051.svg" alt="Image" />
                                <h5>Customer Relation Management</h5>
                            </div>
                            <div className="item">
                                <img src="https://uploads-ssl.webflow.com/6380611d34419190c5cb43e2/6380611d344191a445cb440a_Group%2017.svg" alt="Image" />
                                <h5>Compounding Revenue</h5>
                            </div>
                            <div className="item">
                                <img src="https://uploads-ssl.webflow.com/6380611d34419190c5cb43e2/6380611d344191dc65cb4411_Group%2016.svg" alt="Image" />
                                <h5>Content Management</h5>
                            </div>
                            <div className="item">
                                <img src={images[1]} alt="Image" />
                                <h5>Social Media Marketing</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
