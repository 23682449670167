import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg3 from "../assets/img/header-img.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const About = () => {

  const projects = [
    {
      title: "We Boost Your Earnings",
      description: "Our team develops a personal connection with each fan while at the same time generating revenue. This system allows for building loyalty, a super fan army, while simultaneously creating sales throughout the process.",
      imgUrl: "https://uploads-ssl.webflow.com/6380611d34419190c5cb43e2/638673e05a6f90027295ce38_png_20221130_075608_0000_adobe_express-p-1080.png",
    },
    {
      title: "Life of Leisure",
      description: "Our main goal at our management is to make sure that you have a life of luxury & leisure You're ONLY task is to create content. We handle all the heavy lifting without you having to lift a finger. ",
      imgUrl: projImg3,
    },
    {
      title: "Micro-Analyzation to Success",
      description: "We're equal parts strategists, chatters/sexters, and consulting firms all rolled into one. We seek first to understand what's driving (or hindering) growth within your account and how we can unlock growth opportunities to take your revenue to where it belongs.",
      imgUrl: "https://maxmo-management.de/wp-content/uploads/2021/12/layout-wwebseite-533x1024.png",
    },
  ];

  return (
    <section className="project" id="about-us">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>About us</h2>
                <br/>
                <br/>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Successes</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Why Us?</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Our Values</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                    <div className="section-three">
                        <h3 className="hh3">Here's Why</h3>
                        <h1 className="hh1">We are Great</h1>
                        <p className="pp3">Sugar is an Onlyfans advertising marketing agency, which focuses on all of the admin processes in the business, and supports you to be the amongst the top 1% content creators.</p>
                    </div>
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <div className="section-three">
                        <h1 className="hh1">Why Work with us?</h1>
                        <p className="pp3">Our goal is to make things as easy as possible for you, having you focus on what you do best, which is entertain your fan base.</p>




                        <div class="why-choose-box-sec">
								<div class="why-choose-box">
										<div class="why-choose-box-icon">
						<span><img src="https://findfans.io/wp-content/uploads/2023/01/girl-roaster-icon.svg" alt="choose-icon"/></span>
					</div>
										<h5>10+</h5>
					<p>girls on our roster</p>
				</div>
								<div class="why-choose-box">
										<div class="why-choose-box-icon">
						<span><img src="https://findfans.io/wp-content/uploads/2023/01/monthly-income-icon.svg" alt="choose-icon"/></span>
					</div>
										<h5>$1,000,000+</h5>
					<p>in monthly income</p>
				</div>
								<div class="why-choose-box">
										<div class="why-choose-box-icon">
						<span><img src="https://findfans.io/wp-content/uploads/2023/01/team-member-icon.svg" alt="choose-icon"/></span>
					</div>
										<h5>20+</h5>
					<p>team members and specialists to help out girls</p>
				</div>

							</div>




                      </div>                        
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      
                      <div className="section-three">
                        <h1 className="hh1">Our Values</h1>
                        <h3 className="hh3">How we work</h3>
                        <p className="pp3">The key to a successful life always lies in the connection one creates, invests, and cares for. At SUGAR that is exactly what we do. We believe in the power of personal connection and therefore heavily rely on the following values.</p>

                        <div className="values-row">
                          <ValuesRowCard heading={headingpara[0].heading} paragraph={headingpara[0].paragraph} />
                          <ValuesRowCard heading={headingpara[1].heading} paragraph={headingpara[1].paragraph} />
                          <ValuesRowCard heading={headingpara[2].heading} paragraph={headingpara[2].paragraph} />
                        </div>
                      </div>  

                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}




const headingpara = [
  {
    heading: "Trust and Respect",
    paragraph: "We trust each other. We believe in each other. We respect each other and in particolare respect each others privacy. At SUGAR it is like family, while we always keep it a 100% professional."
  },

  {
    heading: "Reliability",
    paragraph: "We rely on each other. What we say, we do, no exceptions. It is that simple."
  },

  {
    heading: "Passion",
    paragraph: "Everyone at SUGAR is driven to deliver the best possible results. We fully believe in the fact, that people are at their best when they do what they really love. That is how we approach work and also working with our partners."
  }
]


function ValuesRowCard({heading, paragraph}) {
  return (
    <div className="values-row-card">
    <h3>{heading}</h3>
    <p>{paragraph}</p>
  </div>  )
}
